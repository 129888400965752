import React, { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

const queryClient = new QueryClient();

const MainPage = React.lazy(() => import("./pages/main"));
const PostsPage = React.lazy(() => import("./pages/posts"));
const PostPage = React.lazy(() => import("./pages/post"));
const AboutPage = React.lazy(() => import("./pages/about"));
const AboutVogPage = React.lazy(() => import("./pages/about-vog"));
const AboutDocumentsPage = React.lazy(() => import("./pages/about-documents"));
const VogLocalManagersPage = React.lazy(
  () => import("./pages/vog-local-managers")
);
const VogManagersPage = React.lazy(() => import("./pages/vog-managers"));
const ContactsPage = React.lazy(() => import("./pages/contacts"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/posts",
    element: <PostsPage />,
  },
  {
    path: "/post",
    element: <PostPage />,
  },
  {
    path: "/about",
    element: <AboutPage />,
  },
  {
    path: "/contacts",
    element: <ContactsPage />,
  },
  {
    path: "/about/vog",
    element: <AboutVogPage />,
  },
  {
    path: "/about/documents",
    element: <AboutDocumentsPage />,
  },
  {
    path: "/vog_managers",
    element: <VogManagersPage />,
  },
  {
    path: "/vog_local_managers",
    element: <VogLocalManagersPage />,
  },
]);

export const App = () => {
  return (
    <Suspense>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </Suspense>
  );
};
